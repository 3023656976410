<template>
    <v-dialog persistent :value="value" width="500">
        <v-card class="text-center">
            <v-card-title class="headline white--text justify-center" :class="color"
                          primary-title>{{ title }}
            </v-card-title>
            <v-icon size="128" class="mt-4">{{ icon }}</v-icon>
            <v-card-text class="d-flex justify-center">
                {{message}}
                <slot></slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn @click="dismissYesNoDialog">{{ cancelText }}</v-btn>
                <v-spacer/>
                <v-btn @click="acceptYesNoDialog" :color="color">{{ okText }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'YesNoDialog',
        props: {
            title: {type: String, default: function() { return this.$t('areYouSure') }},
            message: {type: String, default: ''},
            cancelText: {type: String, default: function() { return this.$t('btn.cancel')}},
            okText: {type: String, default: function() { return this.$t('btn.ok') }},
            value: {type: [Boolean, String, Number], default: ''},
            icon: {type: String, default: 'mdi-alert-circle-outline'},
            color: {type: String, default: 'warning'}
        },
        methods: {
            dismissYesNoDialog() {
                this.$emit('input', false);
                this.$emit('no');
            },
            acceptYesNoDialog() {
                this.$emit('input', false);
                this.$emit('yes');
            }
        }
    }
</script>

