<template>
    <page-layout-nolo :loading="isLoading" :title="title" :back="$t('btn.backToYourAccount')">
        <v-container>
            <v-card class="mb-10 elevation-8 py-4 px-0">
                <v-card-text>
                    <vehicle-edit v-model="editVehicle" @valid="value => isValid = value"></vehicle-edit>
                </v-card-text>
                <v-card-actions class="px-4">
                    <v-row dense>
                        <v-col v-if="!adding" cols="12" sm="4" md="3" class="pt-0 pb-2 pr-md-6">
                            <v-btn @click="deleteVehicleAsk" outlined block color="primary" class="mr-2">{{ $t('btn.delete') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" class="pt-0 pb-2">
                            <v-btn outlined block @click="cancel" color="secondary" class="mr-2">{{ $t('btn.cancel') }}</v-btn>
                        </v-col>
                        <v-col cols="12" sm="6" class="pt-0 pb-2">
                            <v-btn block :disabled="!isValid" @click="save" color="primary">{{ $t('btn.saveVehicle') }}</v-btn>
                        </v-col>
                    </v-row>

                </v-card-actions>
            </v-card>
        </v-container>
        <yes-no-dialog @yes="deleteVehicle" v-model="showYesNo" color="primary"/>
    </page-layout-nolo>
</template>

<script>
    import VehicleEdit from "../../components/Account/VehicleEdit";
    import APICustomer from "../../api/APICustomer";
    import YesNoDialog from "../../components/UI/YesNoDialog";

    export default {
        name: "EditVehicle",
        components: {YesNoDialog, VehicleEdit},
        data() {
            return {
                showYesNo: false,
                isLoading: false,
                isSaving: true,
                isValid: false,
                defaults: {},
                editVehicle: null,
                defaultVehicle: false
            }
        },
        mounted() {
            if (this.adding) {
                this.editVehicle = {
                    "name": this.customer.firstName + ' ' + this.customer.lastName,
                    "mobile": this.customer.phoneNumber,
                    "vehicle_colour": "",
                    "vehicle_make_model": "",
                    "vehicle_license": "",
                }
            } else {
                this.editVehicle = this.customer.vehicles[this._indexForVehicleId(this.vehicleNo)];
            }
        },
        methods: {
            cancel() {
                this.$router.back();
            },
            save() {
                const me = this;
                APICustomer.updateVehicle(this, this.editVehicle, this.defaultVehicle)
                    .then(() => {
                        let newCustomer = {
                            ...me.customer,
                        };
                        newCustomer.vehicles[this._indexForVehicleId(this.vehicleNo)] = this.editVehicle;
                        if (this.defaultVehicle) {
                            newCustomer.vehicleId = this.vehicleNo;
                        }
                        me.customer = newCustomer;
                        me.$router.back();
                    })
                    .catch(
                        () => {
                            me.appShowOops()
                        }
                    )
            },
            deleteVehicleAsk() {
                this.showYesNo = true;
            },
            deleteVehicle() {
                const me = this;
                APICustomer.removeVehicle(this, this.vehicleNo)
                    .then(() => {
                        let newCustomer = {
                            ...me.customer,
                        };
                        newCustomer.vehicles = JSON.parse(JSON.stringify(newCustomer.vehicles));
                        newCustomer.vehicles.splice(this._indexForVehicleId(this.vehicleNo), 1);
                        me.customer = newCustomer;
                        me.$router.back();
                    })
                    .catch(
                        () => {
                            me.appShowOops()
                        }
                    )
            },
            _indexForVehicleId(vehicleId) {
                for (let idx in this.customer.vehicles) {
                    if (this.customer.vehicles[idx].vehicle_id === vehicleId) return idx;
                }
                return 0;
            }
        },
        computed: {
            adding() {
                return this.vehicleNo === 'add';
            },
            title() {
              return this.adding ? this.$t('addVehicle') : this.$t('editVehicle');
            },
            vehicleNo() {
                return this.$route.params.id;
            },
            customer: {
                get() {
                    return this.$store.getters['session/customer'];
                },
                set(newValue) {
                    this.$store.commit('session/customer', newValue)
                }
            }
        },
    }
</script>

<style scoped>

</style>
